#imprint, #privacy {
  padding: 0 4rem;
  padding-bottom: 2rem;

  h1 {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  h3 {
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-decoration: underline;
  }

  h4 {
    margin-top: 2rem;
    margin-bottom: 1.25rem;
  }
  
}

.imprint-menu {
  font-size: 12px;
  letter-spacing: 1.5px;
}
