//=================================================
// Header
//=================================================

.site-header {
	position: relative;
	background-color: $header_background_color;
	color: $header_color;

	.wrapper {
		padding-top: $header_padding_t;
		padding-bottom: $header_padding_b;

		&:not(.padding-right) {
			padding-right: 0;

			@media #{$media_sm} {
				padding-right: $wrapper_p_r_sm;
			}
		}
	}
}

$header_link_space: 3.895rem;
$header_actions_space: 7.158rem;

.site-header, .modal-header {
	.header-content { .header-left {
			flex: 1 1 0;

			.links {
				.links-items {
					@extend .d-flex;
					@extend .align-items-center;

					.links-item {
						margin-right: $header_link_space;


						@media #{$media_xxl} {
							margin-right: $header_link_space / 2;
						}
					}
				}

				@media #{$media_md} {
					display: none;
				}
			}

			.search-toggle {
				display: none;

				@media #{$media_md} {
					display: block;
				}
			}
		}

		.header-center {
			.header-logo {
				padding-right: $wrapper_p_r;

				@media #{$media_xxl} {
					padding-right: $wrapper_p_r_xxl;
				}

				@media #{$media_xl} {
					padding-right: $wrapper_p_r_xl;
				}

				@media #{$media_sm} {
					padding-right: 0;
				}
			}
		}

		.header-right {
			flex: 1 1 0;

			.d-flex {
				> * {
					margin-right: $header_link_space;

					@media #{$media_xxl} {
						margin-right: $header_link_space / 2;
					}

					@media #{$media_sm} {
						margin-right: 0;
					}
				}

				@media #{$media_md} {
					.search-toggle {
						display: none;
					}
				}
			}
		}
	}
}


.header-absolute-true {
	header {
		&.site-header {
			background-color: $header_absolute_background_color;
			position: absolute;
			width: 100%;
			z-index: 1040;
			min-width: 320px;

			// Color
			color: $header_absolute_color;

			h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cit, code,
			del, dfn, em, ins, q, samp, small, strong, sub, sup, b, i, hr, dl, dt, dd,
			ol, ul, li, fieldset, legend, label, span, caption, figcaption, details, hgroup, summary, mark, audio, video {
				color: inherit;
			}

			.btn {
				color: inherit;

				&.btn-before-dot {
					&:before {
						color: inherit;
					}
				}

				&.btn-after-dot {
					&:after {
						color: inherit;
					}
				}
			}

			a, .btn {
				color: inherit;

				&.btn-link {
					@extend .transition-opacity;

					&:hover {
						opacity: 0.5;
					}
				}
			}
		}
	}
}

body {
	&:not([class*='page-header-absolute-and-no-title-and-content-full-width']) {
		&.header-absolute-true {
			main {
				padding-top: 0;

				> .content {
					> .clearfix {
						padding-top: 8.421rem;

						> section:first-child,
						> div:first-child,
						> header:first-child,
						> footer:first-child,
						> main:first-child,
						> article:first-child,
						> aside:first-child,
						> nav:first-child {
							> section:first-child,
							> div:first-child,
							> header:first-child,
							> footer:first-child,
							> main:first-child,
							> article:first-child,
							> aside:first-child,
							> nav:first-child {
								padding-top: 8.421rem;
								margin-top: -8.421rem;
							}
						}
					}
				}
			}
		}
	}
}

[class*='page-header-absolute-and-no-title-and-content-full-width'] {
	&.header-absolute-true {
		main {
			padding-top: 0;

			> .content {
				> .clearfix {
					padding-top: 8.421rem;

					[class*='section-wrap'] {
						> section:first-child,
						> div:first-child,
						> header:first-child,
						> footer:first-child,
						> main:first-child,
						> article:first-child,
						> aside:first-child,
						> nav:first-child {
							padding-top: 8.421rem;
							margin-top: -8.421rem;
						}
					}
				}
			}
		}
	}
}

nav.navbar {
  // background: rgba(255,255,255,0.3);
  background: $header-background-color;
  position: fixed!important;
  height: 70px;

  ul.nav li.nav-item {
    font-size: 16px;
  }
  
  .nav-item {
    padding: 0.7895rem 1rem;
  }

  .nav-link {
    padding: 0.25rem 0;
  }

  .nav-link.page-title {
    display: none;
  }

  .nav-link.active {
    border-bottom: 1px solid $secare_green;
  }

}
