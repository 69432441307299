
.scroll-element {
  height: 70px;
  margin-top: -70px;
}

#scroller {
  .scroll-arrow {
    font-size: 105px;
    position: fixed;
    z-index: 99;
    left: 50%;
    margin-left: -50px;
    text-align: center;
    width: 100px;

    i { 
      color: rgba(255,255,255, 0.5);
      cursor: pointer;
    }
    i.clicked {
      color: rgba(0,0,0, 0.5);
    }
    i.hidden {
      display: none;
    }
    i.disabled {
      display: none;
    }
    i:hover { 
      color: rgba(0,0,0, 0.85);
    }
  }
}

#up-arrow {
  top: 70px;
}

#down-arrow {
  position: fixed;
  bottom: 0.5vh;
}
