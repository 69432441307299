#book {
  .opening-hours p {
    line-height: 1.25;
  }
  
  .img {
    background-image: url("../../img/secare/scissors.jpg");
  }

}
