$team_font_color: #f0eeea;

#about-us {

  .team {
    margin-bottom: 2rem;
  }

  .team-member {
    margin-bottom: 2rem;
  }

  @include media-breakpoint-up(md) {
    .team-member {
      margin-bottom: 0;
    }
  }

  background: $secare_red;
  color: $team_font_color;


  h2 {
    color: $team_font_color;
  }

  hr {
    margin: 2rem 0;
    color: #f0eeea;
  }
}

.team-img {
  position: relative;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  min-height: 500px;
}

.team-img-mobile {
  min-height: 600px;
}

.team-text, .salon-text {
  @include media-breakpoint-up(sm) { 
    padding: 2rem 3rem;
  } 

  padding: 2rem 0;
  margin: 3rem 0;
}

.salon-text {
  margin: 0 0 2rem 0!important;
  text-align: center;
  font-size: 110%;
}

.team-member {
  @extend .style-default;

  .img {

    &.object-fit {
      &:before {
        padding-top: 118.5%;
      }

      &.original {
        filter: none;
        -webkit-filter: none;
      }
    }
  }

  .team-member-content {
    padding-top: $space_xs;

    .team-member-position {
      margin-bottom: $space_xs - 0.465rem;
    }

    .team-member-t-head {
      color: $body_headline_color;
    }
  }

  .team-member-nav-items {
    padding-top: $space_xs;

    ul {
      li {
        margin-right: 1.368rem;

        &:last-child {
          margin-right: 0;
        }

        a {
          font-size: 0.947rem;
        }
      }
    }
  }
}

.salon-headline {
  margin-top: 2rem;
  text-align: center;
  padding-bottom: 1.5rem!important;
  margin-bottom: 0;
}

.row.salon-pics {

  .col-md-6:nth-child(even) {
    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
    margin-top: 1.5rem;
  }

  &.salon-pics-margin {
    margin-top: 1.5em;
  }
}


.salon-picture > img {
  width: 100%;
}
