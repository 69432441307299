#prices {

  .description {
    font-style: italic;
    margin-bottom: 1rem;
    padding-left: 2rem;
  }

  .prices {

    .price {
      padding: 0.5rem 0rem 0.5rem 0.5rem;
      display: flex;
      flex-direction: row;

      .item {
        flex-grow: 2;
      }
    }
    .price-annotation {
      font-size: 75%;
      padding: 1rem;
      margin-bottom: 0;
      margin-top: -0.5rem;
    }
  }

  .prices h4 {
    margin-top: 2rem;
  }
}
