$side_drawer_width: 300px;

#side-drawer {
  height: 100vh;
  width: $side_drawer_width;
  top: 0;
  right: -$side_drawer_width;
  z-index: 1032;
  transition: right 0.5s ease;

  &.visible {
    right: 0;
  }

  .side-drawer-content {
    background: $body_background_color;
    display: flex;
    flex-direction: column;

    .side-drawer-nav {
      flex-grow: 1;

      display: flex;
      justify-content: space-between;
      flex-direction: column;

      i.close-side-drawer {
        font-size: 40px;
        text-align: center;
        display: block;
        color: $dark;
        width: 100%;
      }

      .close-container {
        display: flex;
        align-items: center;
        margin: 0 -1.5rem;
        border-top: 1px solid $dark;
        padding: 2rem 0 0.5rem 0;
      }

    }
  }

  ul.nav {
    .nav-item {
      font-size: 15px;
      padding: 0.9rem 1.579rem;
    }
  }

  .side-drawer-header {

    padding: 2em;

    .side-drawer-img {
      height: 100px;
    }
  }
}

#side-drawer-void {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1031;
  background: rgba(0,0,0,.6);
}
