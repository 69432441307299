//=================================================
// Logo
//=================================================

/*=============================
// Loading
=============================*/
.loading {
    .logo {
        img {
            width: 20rem;
        }
    }
}

/*=============================
// Header
=============================*/
nav.navbar {
    .navbar-brand {
        img {
            width: 85px;
        }
    }
}

