#philosophy {
  background: $secare_grey;
  color: $body_text_primary_color;

  .philosophy-text {
    margin-bottom: 3rem;
    padding: 2rem;
    background: rgba(0,0,0,0.3);
    color: $body_text_tertiary_color;
  }

  h4 {
    margin: 2rem 0 1rem 0;
  }
}
